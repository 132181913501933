import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components & Utils
import Layout from "../layout/Layout"
import Seo from '../utils/Seo'
import Button from '../components/Button'

// STYLE
const ErrorPage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
`

// markup
const NotFoundPage = ({ data }) => {
  const doc = data.prismic404Page.data

  return (
    <Layout>
      <Seo pageTitle={doc.page_title.text} />
      <ErrorPage className={`container`}>
        <h1>{doc.page_title.text}</h1>
        <p>{doc.page_paragraph}</p>
        <Button type="primary" to="/">{doc.redirect_btn_label}</Button>
      </ErrorPage>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    prismic404Page {
      data {
        page_title {
          text
        }
        page_paragraph
        redirect_btn_label
      }
    }
  }
`